allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.cap = main.gui.cap || {};

    var g = main.gui.cap;

    var croppieInstance;
    var croppieInstanceVerso;

    function readFile(input, cropper, $container) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $container.data('rotation', 0);
                cropper.bind({
                    // remove exif data so cropper doesn't auto rotate image and leaves rotation to 0 resulting in out of sync 'rotation'
                    // and thus failing creating correct thumbs
                    url: removeExifData(e.target.result)
                }).then(function () {

                });
            };
            reader.readAsArrayBuffer(input.files[0]);
        }
        else {
            main.utils.showError("Sorry - something went wrong");
        }
    }

    function removeExifData(result){
        var dv = new DataView(result);
        var offset = 0, recess = 0;
        var pieces = [];
        var i = 0;
        if (dv.getUint16(offset) == 0xffd8){
            offset += 2;
            var app1 = dv.getUint16(offset);
            offset += 2;
            while (offset < dv.byteLength){
                //console.log(offset, '0x'+app1.toString(16), recess);
                if (app1 == 0xffe1){

                    pieces[i] = {recess:recess,offset:offset-2};
                    recess = offset + dv.getUint16(offset);
                    i++;
                }
                else if (app1 == 0xffda){
                    break;
                }
                offset += dv.getUint16(offset);
                var app1 = dv.getUint16(offset);
                offset += 2;
            }
            if (pieces.length > 0){
                var newPieces = [];
                pieces.forEach(function(v){
                    newPieces.push(result.slice(v.recess, v.offset));
                }, this);
                newPieces.push(result.slice(recess));
                var br = new Blob(newPieces, {type: 'image/jpeg'});

                return  URL.createObjectURL(br);
            }
        }

        return URL.createObjectURL(new Blob([result]));
    }

    g.init = function () {
        // if errors are present, open up the correction tab
        if ($('#corrections .has-error').length > 0) {
            $('#jsCorrectionsTab').tab('show');
        }

        g.initImageInput();
        initMyNotes();
        initRectoVerso();
    };

    g.startImageCorrection = function () {
        $('#jsCorrectionsTab').tab('show');
        document.getElementById('image_input').click();
    };

    // init cropper on file change
    g.initImageInput = function () {
        $('#image_input').on('change', function () {
            croppieInstance = startCropper($('#jsCropper')[0], croppieInstance, $('.jsCropperSelect, .jsCropperSelectRecto'),$('.jsCropperContainer'));
            readFile(this, croppieInstance, $('.jsCropperContainer'));
        });
        $('#image_input_verso').on('change', function () {
            croppieInstanceVerso = startCropper($('#jsCropperVerso')[0], croppieInstanceVerso, $('.jsCropperSelect, .jsCropperSelectVerso'), $('.jsCropperVersoContainer'));
            readFile(this, croppieInstanceVerso, $('.jsCropperVersoContainer'));
        });
        initColorCheckboxes();
    };

    function updateCropData(instance, rotation, $cropperContainer) {
        var points = instance.get().points;
        var data = {
            'x1': points[0],
            'y1': points[1],
            'x2': points[2],
            'y2': points[3],
            'rotation': rotation % 360
        };

        $cropperContainer.find('.jsImageCropData').val(JSON.stringify(data));
    }

    function startCropper($cropperCanvasDiv, instance, elementsToHide, $cropperContainer) {

        if (instance !== undefined) {
            return instance;
        }

        // init the cropper
        instance = new Croppie($cropperCanvasDiv, {
            viewport: {width: 440, height: 440, type: 'circle'},
            boundary: {width: 450, height: 450},
            enableOrientation: true,
            update: function (cropDetails) {

                updateCropData(instance, $cropperContainer.data('rotation'), $cropperContainer);
            }
        });

        // show the cropper, hide the upload field
        elementsToHide.toggleClass('hidden', true);
        $cropperContainer.toggleClass('hidden', false);

        // init rotate buttons
        $cropperContainer.find('.jsCropperRotate').on('click', function (ev) {
            var degrees = parseInt($(this).data('rotate'));
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            newRotation += degrees;
            newRotation = normaliseRotationValue(newRotation, parseInt($cropperContainer.data('rotation')));

            // change slider value
            $cropperContainer.find('.jsRotationSlider').val(newRotation).change();
        });

        // rotate cropper + store rotation
        $cropperContainer.find('.jsRotationSlider').on('change', function (ev) {
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            newRotation = normaliseRotationValue(newRotation, parseInt($cropperContainer.data('rotation')));

            $cropperContainer.data('rotation', newRotation);
            instance.rotate(newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });

        return instance;
    }

    function normaliseRotationValue(value, oldValue){
        if (isNaN(value)) {
            value = 0;
        }
        if(value < 0){
            value += 360;
        }
        if(value >= 360){
            value %= 360;
        }
        if (value === 90 || value === 270) {
            if (oldValue > value) {
                value--;
            } else {
                value++;
            }
        }

        return value;
    }

    // init notes on blur/change + ajax save
    function initMyNotes() {
        // show buttons to save the note on focus
        $('#myNotes')
            .on('focus blur', function () {
                if($('.jsNoteButtons').hasClass('collapse')){
                    $('.jsNoteButtons').collapse('show');
                }
            });
    }

    // init recto verso
    function initRectoVerso() {
        // show buttons to save the note on focus
        $('.jsToggleRectoVerso')
            .on('click', function () {

                $('#recto, #verso').hide();
                $('#' +$(this).data('type')).show();

                $('.jsToggleRectoVerso').removeClass('btn-success');
                $(this).addClass('btn-success');
            });
    }

    // handle color checkboxes
    function initColorCheckboxes() {
        // disable/deactivate other colors if polychrome is selected
        $(':checkbox[data-color="polychrome"]')
            .on('change', function () {

                var isActive = $(this).prop('checked');
                $(':checkbox[data-group="'+ $(this).data('group')+'"]').not('[data-color="polychrome"]')
                    .prop('checked', false)
                    .prop('disabled', isActive)
                    // fade text
                    .parent().toggleClass('text-muted', isActive)
                    // fade color circle
                    .find('.color').fadeTo(0, isActive ? 0.5 : 1);
                $(this).prop('disabled', false);
            });

        $(':checkbox[data-color="polychrome"]:checked').trigger('change');
    }

    return main;

}(window.allcaps || {}, jQuery);
